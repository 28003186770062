@import '../../../styles/variables';

.skillsWrapper {
  overflow: auto;
}

.skillsTree {
  overflow: auto;

  margin-top: 4px;

  > ol {
    display: flex;
    justify-content: space-evenly;
  }

  p {
    margin: 0;
  }

  ol,
  li {
    font-size: 14px;

    padding-left: 10px;

    list-style: georgian;
  }

  li {
    padding-left: 5px;
  }
}

.level1 {
  color: $green300;

  li {
    list-style: lao;
  }
}

.level2 {
  color: $green200;

  li {
    list-style: devanagari;
  }
}

.level3 {
  color: $green100;
}


.asterisk,
.note {
  color: $green300;
}