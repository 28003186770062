/* Red - Ruby */
$red100: hsl(354, 69%, 90%);
$red200: hsl(354, 69%, 58%);
$red300: hsl(354, 69%, 54%);
$red400: hsl(354, 69%, 50%);
$red500: hsl(354, 69%, 46%);
$red600: hsl(354, 69%, 42%);
$red700: hsl(354, 69%, 38%);
$red800: hsl(354, 69%, 34%);
$red900: hsl(354, 69%, 30%);

/* Orange - Spessartite Garnet */
$orange100: hsl(27, 94%, 90%);
$orange200: hsl(27, 94%, 55%);
$orange300: hsl(27, 94%, 51%);
$orange400: hsl(27, 94%, 47%);
$orange500: hsl(27, 94%, 43%);
$orange600: hsl(27, 94%, 39%);
$orange700: hsl(27, 94%, 35%);
$orange800: hsl(27, 94%, 31%);
$orange900: hsl(27, 94%, 27%);

/* Yellow - Citrine */
$yellow100: hsl(55, 73%, 90%);
$yellow200: hsl(55, 73%, 58%);
$yellow300: hsl(55, 73%, 54%);
$yellow400: hsl(55, 73%, 50%);
$yellow500: hsl(55, 73%, 46%);
$yellow600: hsl(55, 73%, 42%);
$yellow700: hsl(55, 73%, 38%);
$yellow800: hsl(55, 73%, 34%);
$yellow900: hsl(55, 73%, 30%);

/* Green - Jade */
$green100: hsl(158, 100%, 86%);
$green200: hsl(158, 100%, 45%);
$green300: hsl(158, 100%, 41%);
$green400: hsl(158, 100%, 37%);
$green500: hsl(158, 100%, 33%);
$green600: hsl(158, 100%, 29%);
$green700: hsl(158, 100%, 25%);
$green800: hsl(158, 100%, 21%);
$green900: hsl(158, 100%, 17%);

/* Blue - Sapphire */
$blue100: hsl(216, 85%, 90%);
$blue200: hsl(216, 85%, 51%);
$blue300: hsl(216, 85%, 47%);
$blue400: hsl(216, 85%, 43%);
$blue500: hsl(216, 85%, 39%);
$blue600: hsl(216, 85%, 35%);
$blue700: hsl(216, 85%, 31%);
$blue800: hsl(216, 85%, 27%);
$blue900: hsl(216, 85%, 23%);

/* Purple - Amethyst */
$purple100: hsl(270, 50%, 90%);
$purple200: hsl(270, 50%, 70%);
$purple300: hsl(270, 50%, 66%);
$purple400: hsl(270, 50%, 62%);
$purple500: hsl(270, 50%, 58%);
$purple600: hsl(270, 50%, 54%);
$purple700: hsl(270, 50%, 50%);
$purple800: hsl(270, 50%, 46%);
$purple900: hsl(270, 50%, 42%);

/* Grey - Hematite */
$neutral100: hsl(190, 2%, 90%);
$neutral200: hsl(190, 2%, 64%);
$neutral300: hsl(190, 2%, 60%);
$neutral400: hsl(190, 2%, 56%);
$neutral500: hsl(190, 2%, 52%);
$neutral600: hsl(190, 2%, 48%);
$neutral700: hsl(190, 2%, 44%);
$neutral800: hsl(190, 2%, 40%);
$neutral900: hsl(190, 2%, 26%);

/* Misc */
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);