@import '../../../styles/variables';

.wrapper {
  text-align: center;
  h3 {
    margin-bottom: 0;

    color: $red200;
    a {
      &:hover,
      &:active,
      &:visited,
      &:link {
        color: $red200;
      }
    }
  }
}

.links {
  font-size: 13px;

  margin-top: 1px;
  margin-right: 5px;

  color: $neutral100;

  &:after {
    content: ' |';;

    color: $neutral200;
  }

  a {
    transition: color ease 0.2s;
    &:active,
    &:visited,
    &:link {
      color: $neutral100;
    }

    &:hover {
      color: $red200;
    }
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;

    &:after {
      content: none;
    }
  }
}

.imgWrapper {
  padding: 10px;
}

.img {
  position: relative;

  width: calc(100% - 20px);
  max-width: 650px;
  margin-top: 10px;

  transition: all ease 0.4s;

  border-radius: 6px;
  box-shadow: 0 3px 5px rgba(0,0,0, 0.55);

  &:hover {
    box-shadow: 0 3px 5px rgba(0,0,0, 1);
  }
}

.techStack {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  color: $neutral200;

  @media only screen and (max-width: 500px) {
    flex-direction: column;

    margin-bottom: 20px;
  }
}

.desc {
  color: $neutral200;
}

.note {
  font-size: 14px;

  color: $red100;
  span {
    font-size: 14px;

    margin-right: 5px;
  }
}