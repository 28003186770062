$width: 96%;
$gutter: 4%;
$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-small,
  md: $breakpoint-med,
  lg: $breakpoint-large
) !default;
$spacer: 1rem !default;
$spacer1: $spacer * 0.25;
$spacer2: $spacer * 0.5;
$spacer3: $spacer;
$spacer4: $spacer * 1.5;
$spacer5: $spacer * 3;
$spacers: (
  0: 0,
  1: $spacer1,
  2: $spacer2,
  3: $spacer3,
  4: $spacer4,
  5: $spacer5
);