@import '../../../../styles/variables';

.testimonialWrapper {
  position: relative;

  max-height: 300px;
  margin-top: 20px;
  padding: 10px;
  &:before,
  &:after {
    position: absolute;

    width: 50px;
    height: 50px;

    content: '';

    border: solid $purple200;
    border-width: 2px 2px 0 0;
  }

  &:before {
    top: 0;
    left: 0;

    border-width: 2px 0 0 2px;
  }

  &:after {
    right: 0;
    bottom: 0;

    border-width: 0 2px 2px 0;
  }
}
.testimonial {
  font-size: 14px;
  font-style: italic;

  position: relative;
  z-index: 2;

  overflow: auto;

  height: 100%;
  margin-bottom: 1em;

  color: $purple100;
}

.person {
  margin-top: 20px;

  text-align: right;

  color: $purple100;

  justify-self: flex-end;

  p,
  h4,
  div,
  a {
    margin-bottom: 0;
  }

  p {
    font-size: 12px;

    color: $purple100;
  }

  h4 {
    color: $purple400;
  }
}

.position {
  font-size: 14px;

  a {
    color: $purple200;
  }
}