@import '../../../styles/variables';

.contact {
  width: 100%;
  margin-top: 20px;
}

.socialMedia {
  justify-content: space-evenly;

  width: 100%;
  margin-bottom: 10px;
  a {
    margin-bottom: 10px;
    &:visited,
    &:active,
    &:link {
      color: $neutral100;
    }

    &:hover {
      color: $neutral200;
      path {
        fill: $neutral200;
      }
    }
  }

  svg {
    margin-right: 10px;
    path {
      fill: $white;
    }
  }
}