@import '../../styles/variables';

.grid {
  display: grid;
  overflow: auto;

  margin: 1em;

  transition: all ease 0.5s;

  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: repeat(3, calc(100% / 3));
  grid-template-rows: repeat(2, calc((100vh - 2em) / 2));

  > div {
    position: relative;
    z-index: 1;

    padding: 1em;

    border-right: 1px solid $neutral800;
    border-bottom: 1px solid $neutral800;

    &:nth-child(3n) {
      padding-right: 0;

      border-right: 0;
    }

    &:nth-child(n+4) {
      padding-bottom: 0;

      border-bottom: 0;
    }

    &:nth-child(-n+3) {
      padding-top: 0;
    }

    &:nth-child(3n + 1) {
      padding-left: 0;
    }
  }
}

.header {
  text-align: center;

  color: $white;
}

@media only screen and (min-width: 881px) and (max-width: 1400px) {
  .grid {
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-template-rows: repeat(3, calc(50vh - 0.5em + 1px));
    > div {
      &:nth-child(n+3):nth-last-child(n+3) {
        padding: 1em 0;

        border-bottom: 1px solid $neutral800;
      }
      &:nth-child(odd) {
        padding-right: 1em !important;
        padding-left: 0;

        border-right: 1px solid $neutral800;
      }
      &:nth-child(even) {
        padding-right: 0;
        padding-left: 1em !important;

        border-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .grid {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(6, calc(50vh - 0.5em + 1px));
    > div {
      &:nth-child(n+1) {
        padding: 1em 0;

        border-right: 0;
        border-bottom: 1px solid $neutral800;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        border-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(6, 100vh);
    > div {
      &:nth-child(n+1) {
        padding: 1em 0;

        border-right: 0;
        border-bottom: 1px solid $neutral800;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        border-bottom: 0;

        > div > div {
          flex-direction: column;

          a {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}