@import '../../../styles/variables';

.timeline {
  position: relative;

  margin-bottom: 0;

  list-style: none;

  &:before {
    position: absolute;
    top: 0;
    left: 1em;

    width: 2px;
    height: 100%;
    margin-left: -1px;

    content: '';

    background-color: $orange500;
  }
}

.event {
  position: relative;
  left: -3.5em;
}

.icon {
  position: absolute;
  top: 0.5em;
  left: 2em;

  display: block;

  width: 0.5em;
  height: 0.5em;
  margin: 0.25em 0.25em 0.25em -0.25em;

  transform: rotate(45deg);

  outline: 8px solid $neutral900;
  background-color: $orange300;
}

.body {
  position: relative;
  top: -1.875em;
  left: 2em;

  padding: 2em 0 0 1em;

  cursor: default;

  div {
    transition: all ease 0.2s;
  }

  h3 {
    color: $neutral100;
  }
  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    color: $orange200;
  }

  &:hover {
    > div {
      &:nth-last-child(-n+2) {
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
}

.bodyHeader {
  display: flex;
  align-items: baseline;

  h3,
  h4 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  h4 {
    margin-left: 10px;

    color: $neutral300 !important;
  }
}

.date {
  font-size: 14px;

  display: inline-block;

  margin-bottom: 0;

  color: $orange200;

  span {
    margin-left: 5px;

    color: $neutral200;
  }
}

.description {
  font-size: 14px;

  margin-bottom: 0.75em;

  color: $neutral100;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 700;
  }
}