@import '../../../styles/variables';

.carousel {
  display: grid;

  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 9fr 1fr;

  > div {
    align-self: center;

    justify-self: center;
  }

  > ul {
    align-self: center;

    grid-column: 2;
    justify-self: center;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: pointer;
  user-select: none;
  transition: all ease 0.4s;

  color: $neutral200;

  &:hover {
    color: $neutral100;
  }
}

.arrow__prev {
  &:hover {
    padding-right: 10px;
  }
}

.arrow__next {
  &:hover {
    padding-left: 10px;
  }
}

.slides {
  position: relative;

  width: 100%;
  height: 100%;
}

.pagination {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;

  li {
    width: 7px;
    height: 7px;
    margin: 0 4px 0 0;
    padding: 0;

    list-style: none;

    cursor: pointer;

    border-radius: 50%;
    background-color: $neutral300;

    &:hover {
      background-color: $neutral100;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.pagination__active {
  background-color: $neutral100 !important;
}