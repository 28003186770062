@import '../../../../styles/variables';

.slide {
  position: absolute;
  z-index: 1;

  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;

  height: 100%;

  transition: all ease 0.4s;

  opacity: 0;
}

.slide__active {
  z-index: 2;

  opacity: 1;
}

.quote {
  font-size: 150px;

  position: absolute;
  z-index: 1;

  margin-top: -50px;

  opacity: 0.1;
  color: $purple100;
}