.container {
  width: 90%;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (min-width: $breakpoint-small) {
    width: 80%;
  }

  @media only screen and (min-width: $breakpoint-large) {
    width: 75%;
    max-width: 60rem;
  }
}

.row {
  position: relative;

  width: 100%;
}

.row [class^='col'] {
  float: left;

  min-height: 0.125rem;
  margin: 0.5rem 2%;
}

.row::after {
  display: table;
  clear: both;

  content: '';
}

.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
  width: $width;
}

.col-sm-1 {
  width: calc($width / 12) - calc($gutter * 11 / 12);
}
.col-sm-2 {
  width: calc($width / 6) - calc($gutter * 10 / 12);
}
.col-sm-3 {
  width: calc($width / 4) - calc($gutter * 9 / 12);
}
.col-sm-4 {
  width: calc($width / 3) - calc($gutter * 8 / 12);
}
.col-sm-5 {
  width: calc($width / calc(12 / 5)) - calc($gutter * 7 / 12);
}
.col-sm-6 {
  width: calc($width / 2) - calc($gutter * 6 / 12);
}
.col-sm-7 {
  width: calc($width / calc(12 / 7)) - calc($gutter * 5 / 12);
}
.col-sm-8 {
  width: calc($width / calc(12 / 8)) - calc($gutter * 4 / 12);
}
.col-sm-9 {
  width: calc($width / calc(12 / 9)) - calc($gutter * 3 / 12);
}
.col-sm-10 {
  width: calc($width / calc(12 / 10)) - calc($gutter * 2 / 12);
}
.col-sm-11 {
  width: calc($width / calc(12 / 11)) - calc($gutter * 1 / 12);
}
.col-sm-12 {
  width: $width;
}

@media only screen and (min-width: $breakpoint-med) {
  .col-md-1 {
    width: calc($width / 12) - calc($gutter * 11 / 12);
  }
  .col-md-2 {
    width: calc($width / 6) - calc($gutter * 10 / 12);
  }
  .col-md-3 {
    width: calc($width / 4) - calc($gutter * 9 / 12);
  }
  .col-md-4 {
    width: calc($width / 3) - calc($gutter * 8 / 12);
  }
  .col-md-5 {
    width: calc($width / 12 / 5) - calc($gutter * 7 / 12);
  }
  .col-md-6 {
    width: calc($width / 2) - calc($gutter * 6 / 12);
  }
  .col-md-7 {
    width: calc($width / 12 / 7) - calc($gutter * 5 / 12);
  }
  .col-md-8 {
    width: calc($width / 12 / 8) - calc($gutter * 4 / 12);
  }
  .col-md-9 {
    width: calc($width / 12 / 9) - calc($gutter * 3 / 12);
  }
  .col-md-10 {
    width: calc($width / 12 / 10) - calc($gutter * 2 / 12);
  }
  .col-md-11 {
    width: calc($width / calc(12 / 11)) - calc($gutter * 1 / 12);
  }
  .col-md-12 {
    width: $width;
  }

  .hidden-sm {
    display: block;
  }
}