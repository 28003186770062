@import '../../../styles/variables';

.wrapper {
  position: relative;

  overflow: auto;

  margin-top: 4px;
}

.img {
  width: 75px;
  height: 75px;
  margin-top: 30px;
  margin-bottom: 10px;

  border: 2px solid $yellow100;
  border-radius: 50%;
  background-image: url('./300.jpeg');
  box-shadow: 0 0 5px 0 $yellow100;
}

.name {
  font-weight: bold;

  color: $yellow400;
}

.text {
  font-size: 14px;

  max-width: 85%;

  color: $yellow100;

  p {
    margin-bottom: 7px;

    &:first-child {
      margin-top: 10px;
    }
  }

  a {
    color: $yellow100;

    text-decoration-color: $yellow400;

    &:visited {
      color: $yellow400;
    }

    &:hover {
      color: $yellow700;

      text-decoration-color: $yellow700;
    }

    &:active {
      text-decoration-style: dotted;
    }
  }
}

.highlight {
  padding: 2px 5px;

  background: $yellow900;
}

.emoji {
  font-size: 20px;
}

.sub {
  font-size: 10px;
}